.App {
  text-align: center;
  padding: 1em;
  display: block;
}


.inputText {
  font-size: 17px;
  width: 40%;
  height: 57px;
  padding: 0 15px;
}

.inputText_pass {
  font-size: 17px;
  width: 40%;
  height: 57px;
  padding: 0 15px;
}

.g-recaptcha {
  margin-left: 30%;
  margin-top: 1%;
}

.button {
	height: 40px !important;
  margin-top: -100px !important;
	margin-left: 35% !important;

}

.error{
  color: red;
}

/* For IPads */
@media only screen and (min-width: 700px)and (max-width: 1400px) {

  .responsive{
    max-width: 100%;
  }
  
  .inputText,
  .inputText_pass {
    width: 70%;
    align-self: center;
  }

  .g-recaptcha {
    margin-top: 3%;
    float:left;
    margin-left: 15%;
  }

}

@media only screen and (min-width: 700px) and (max-width: 1400px) and (orientation:landscape) {

  .responsive{
    max-width: 100%;
  }
  
  .inputText,
  .inputText_pass {
    width: 70%;
    align-self: center;
  }

  .g-recaptcha {
    margin-top: 3%;
    float:left;
    margin-left: 15%;
  }
  .button{
    height: 48px !important;
    margin-top: -100px !important;
    margin-left: 61% !important;
  }
}

/* For Phone */
@media only screen and (min-width: 250px) and (max-width: 700px) {
  .responsive{
    max-width: 100%;
  }
  
  .inputText,
  .inputText_pass {
    width: 100%;
    align-self: center;
    width: 300;
    margin: 100;
  }

  .g-recaptcha {
    margin-top: 3%;
    margin-left: 0%;
    transform:scale(0.5);
    transform-origin:0 0;
  }
  .button{
    height: 25px !important;
    margin-top: -150px !important;
    margin-left: 70% !important;
    font-size: 10px !important;
  }

}

@media only screen and (min-width: 250px) and (max-width: 700px) and (orientation:landscape) {

  .responsive{
    max-width: 100%;
  }
  
  .inputText,
  .inputText_pass {
    width: 100%;
    align-self: center;
  }

  .g-recaptcha {
    margin-top: 3%;
    margin-left: 0%;
    transform:scale(1);
    transform-origin:0 0;
  }
  .button{
    height: 35px !important;
    margin-top: -120px !important;
    margin-left: 85% !important;
    font-size: 15px !important;
  }
}

/* For GalaxyFold */
@media only screen and (max-width: 300px) {
  .responsive{
    max-width: 100%;
  }
  
  .inputText,
  .inputText_pass {
    width: 100%;
    align-self: center;
    width: 300;
    margin: 100;
  }

  .g-recaptcha {
    margin-top: 3%;
    margin-left: 0%;
    transform:scale(0.5);
    transform-origin:0 0;
  }
  .button{
    height: 25px !important;
    margin-top: -60px !important;
    margin-left: 0% !important;
    font-size: 10px !important;
  }

}
