.navLink {
    text-decoration: none;
    color: white;
    font-size: 1.75vh;
    float: left;
}

.navLink a:hover {
    color: yellow;
    border-bottom: 1px solid white;
}

.userNavLink {
    color: #1890ff;
    font-size: 1.75vh;
}

.logoutLink {
    display: 'contents';
}

.MuiMenuItem-root {
    padding: 0.5em !important;
}

.logo {
    /* float: left; */
    width: 200px;
    height: 31px;
    margin: 16px 24px 16px 0;
}