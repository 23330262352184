.header {
    position: 'sticky';
    background-color: white;
    width: 100%;
    z-index: 1000;
}

.ant-layout-content {
    width: 100%;
    min-height: calc(100vh - 165px);
    background-color: white;
}

.ant-layout-header {
    padding: 0 20px !important;
}

.ant-layout-footer {
    background: white;
    bottom: 0;
}

.ant-divider {
    border-block-start: 1.5px solid rgba(5, 5, 5, 0.25);
}

#flightDisplay {
    position: absolute;
    top: -5px;
    left: 10px;
    z-index: 1;
    padding: 10px;
    white-space: pre-line;
    font-size: 1.125vw;
  }
  

@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .blinking {
    animation: blink 1.5s infinite; /* You can adjust the animation duration */
  }

  .green-circle {
    width: 0.65em;
    height: 0.65em;
    display: inline-block;
    background: radial-gradient(at 0% 100%, lightgrey 0%, #78ee78 20%, #00ff00 40%, #4caf50 75%, #008000 92.5%, darkgreen 100%);
    border-radius: 50%;
    box-shadow: 0 0 2.5px 2.5px rgba(106, 253, 80, 0.5); /* Adjust the spread radius and color as needed */
  }

  .gray-circle {
    width: 0.65em;
    height: 0.65em;
    display: inline-block;
    background: radial-gradient(at 0% 100%, #f0f0f0 0%, #c0c0c0 20%, #808080 40%, #404040 75%, #202020 92.5%, #000000 100%);
    border-radius: 50%;
    box-shadow: 0 0 2.5px 2.5px lightgrey; /* Adjust the spread radius and color as needed */
  }

  .yellow-circle {
    width: 0.65em;
    height: 0.65em;
    display: inline-block;
    background: radial-gradient(at 0% 100%, #f0f0f0 0%, #ffffcc 20%, #ffff99 40%, #ffff66 60%, #ffff33 75%, #ffff00 92.5%, #e6e600 100%);
    border-radius: 50%;
    box-shadow: 0 0 2.5px 2.5px rgb(252, 252, 97); 
  }

  .icon-border {
    width: 1em;
    height: 0.95em;
    display: inline-block;
    background: radial-gradient(circle, rgba(113, 113, 231, 0.5), rgba(0, 255, 255, 0.5));
    border-radius: 10%;
    box-shadow: 0 0 10px 5px rgba(173, 216, 230, 0.5); /* Adjust the spread and blur radius as needed */
  }

  .outlined-header1 {
    text-shadow: 0 0 7.5px orange, 0 0 20px yellow, -1px -1px 0 lightblue, 1px -1px 0 lightblue, -1px 1px 0 teal, 1px 1px 0 teal;
  }

  .outlined-header2 {
    text-shadow: -1px -1px 0 lightblue, 1px -1px 0 lightblue, -1px 1px 0 teal, 1px 1px 0 teal;
  }

  .neon-glow {
    color: rgb(245, 245, 245); 
    text-shadow: 0 0 7.5px rgb(9, 9, 161), 0 0 20px cyan, -1px -1px 0 lightblue, 1px -1px 0 lightblue, -1px 1px 0 teal, 1px 1px 0 teal;
  }
  